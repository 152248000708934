export default {
  "\u00c3\u0083\u00a1": "á",
  "\u00c3\u0083\u00c2\u00a1": "á",
  "\u00c3\u0084\u0085": "ą",
  "\u00c3\u0083\u0081": "Á",
  "\u00c3\u0083\u00c2\u0081": "Á",
  "\u00c3\u0083\u00e2\u0082\u00ac": "ä",
  "\u00c3\u0083\u00a4": "ä",
  "\u00c3\u0084\u0087": "ć",
  "\u00c3\u0084\u0086": "Ć",
  "\u00c3\u0084\u00c2\u008d": "č",
  "\u00c3\u0084\u00c2\u008c": "Č",
  "\u00c3\u0084\u008f": "ď",
  "\u00c3\u0084\u00c2\u008f": "ď",
  "\u00c3\u0084\u008e": "Ď",
  "\u00c3\u0084\u00c2\u008e": "Ď",
  "\u00c3\u0083\u00c2\u0089": "É",
  "\u00c3\u0083\u00a9": "é",
  "\u00c3\u0084\u00c2\u009b": "ě",
  "\u00c3\u0084\u00c2\u0098": "Ę",
  "\u00c3\u0084\u00c2\u0099": "ę",
  "\u00c3\u0083\u00ad": "í",
  "\u00c3\u0084\u00c2\u00ba": "ĺ",
  "\u00c3\u0084\u00c2\u00be": "ľ",
  "\u00c3\u0084\u00c5\u0093": "Ľ",
  "\u00c3\u0084\u00c2\u00bd": "Ľ",
  "\u00c3\u0085\u0082": "ł",
  "\u00c3\u0085\u0081": "Ł",
  "\u00c3\u0085Â\u0088": "ň",
  "\u00c3\u0083\u00b3": "ó",
  "\u00c3\u0083\u00c2\u00b3": "ó",
  "\u00c3\u0083\u0093": "Ó",
  "\u00c3\u0083\u00c2\u0093": "Ó",
  "\u00c3\u0083\u00b4": "ô",
  "\u00c3\u0083\u00c2\u00b4": "ô",
  "\u00c3\u0083\u00c2\u00b6": "ö",
  "\u00c3\u0085\u00c2\u0091": "ő",
  "\u00c3\u0083\u0094": "Ô",
  "\u00c3\u0083\u00c2\u0094": "Ô",
  "\u00c3\u0085\u00c2\u0095": "ŕ",
  "\u00c3\u0085\u00c2\u0099": "ř",
  "\u00c3\u0085\u00a1": "š",
  "\u00c3\u0085 ": "Š",
  "\u00c3\u0085\u00c2\u009b": "ś",
  "\u00c3\u0085\u00c2\u009a": "Ś",
  "\u00c3\u0085¥": "ť",
  "\u00c3\u0085\u00a4": "Ť",
  "\u00c3\u0083\u00ba": "ú",
  "\u00c3\u0083\u00c2\u00ba": "ú",
  "\u00c3\u0083\u009a": "Ú",
  "\u00c3\u0083\u00c2\u009a": "Ú",
  "\u00c3\u0085\u00af": "ů",
  "\u00c3\u0085\u00b0": "Ű",
  "\u00c3\u0085\u00c2\u00b0": "Ű",
  "\u00c3\u0085\u00b1": "ű",
  "\u00c3\u0085\u00c2\u00b1": "ű",
  "\u00c3\u0083\u00c2\u00bc": "ü",
  "\u00c3\u0083\u00c2\u00bd": "ý",
  "\u00c3\u0085Â½": "Ž",
  "\u00c3\u0085\u00c2\u00be": "ž",
  "\u00c3\u0085\u00c2\u00bc": "ż",
  "Ã¢\u0080Â\u0099": "’",
  Ã: "í"
};
